import { motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import WorkContext from '../utils/WorkContext';

const HeroVideo = ({ videoId, altVideoId, videoPoster, title }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const workContext = useContext(WorkContext);

  return (
    <div className={`hero-video`}>
      <div className='hero-video__wrapper'>
        {!isPlaying && <img src={videoPoster} className="hero-video__poster" /> }
        {(workContext.theme === "light" && altVideoId) ? (
          <ReactPlayer
            className='hero-video__media'
            url={`https://player.vimeo.com/video/${altVideoId}`}
            loop={true}
            muted={true}
            playsinline={true}
            controls={false}
            volume={0}
            onStart={() => setIsPlaying(true)}
            config={{
              file: { 
                attributes: { 
                  poster: videoPoster,
                } 
              },
              vimeo: {
                playerOptions: { autoplay: true, controls: false },
                preload: true,
              },
            }}
          />
        ) : (
          <ReactPlayer
            className='hero-video__media'
            url={`https://player.vimeo.com/video/${videoId}`}
            loop={true}
            muted={true}
            playsinline={true}
            controls={false}
            volume={0}
            onStart={() => setIsPlaying(true)}
            config={{
              file: { 
                attributes: { 
                  poster: videoPoster,
                } 
              },
              vimeo: {
                playerOptions: { autoplay: true, controls: false },
                preload: true,
              },
            }}
          />
        )}
      </div>
      <motion.h1 className='hero-video__title' animate={{ y: [40, 0] }}>
        {title}
      </motion.h1>
    </div>
  );
};

export default HeroVideo;
