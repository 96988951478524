import { graphql } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import HeroVideo from '../components/HeroVideo';
import ProjectCard from '../components/ProjectCard';
import TemplateWrapper from '../components/TemplateWrapper';
import WorkContext from '../utils/WorkContext';

const IndexPage = ({ data }) => {
  const workContext = useContext(WorkContext);

  useEffect(() => {
    workContext.updateWork(data.allWork.edges);
    workContext.updateWorksToDisplay(data.allWork.edges.filter(({ node: work }) => work.featured));
    workContext.updateDirectors(data.allDirectors.nodes);
  }, []);

  return (
    <WorkContext.Consumer>
    {context => (
      <TemplateWrapper>
        {!context.isDirectorListing && (
          <HeroVideo
            videoId={data.datoCmsHome.videoId}
            altVideoId={data.datoCmsHome.altVideoId}
            title={data.datoCmsHome.introText}
          />
        )}

        <div className={`showcase ${!context?.worksToDisplay?.length ? 'empty' : ''}`}>
          {context.worksToDisplay && context.worksToDisplay.map(({ node: work }) => (
            <ProjectCard project={work} key={work.slug} />
          ))}
          {context.worksToDisplay && !context.worksToDisplay.length && context.isDirectorListing && (
            <h4>No works to show</h4>
          )}
        </div>
      </TemplateWrapper>
    )}
  </WorkContext.Consumer>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexQuery {
    allWork: allDatoCmsWork(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          id
          title
          subHeading
          byline
          slug
          featured
          director {
            slug
            name
          }
          client
          year
          category
          videoId
          videoPreviewUrl
          alternativeVideoPreviewUrl
          coverImage {
            fluid(
              maxWidth: 450
              imgixParams: { fm: "jpg", auto: "compress" }
            ) {
              aspectRatio
              src
              srcSet
              sizes
            }
            fixed(width: 1200) {
              src
            }
          }
        }
      }
    }
    allDirectors: allDatoCmsDirector(sort: { fields: [position], order: ASC }) {
      nodes {
        id
        slug
        name
        intro
        instagram
        vimeo
        featuredProject {
          videoPreviewUrl
        }
        avatar {
          alt
          fluid(
            maxWidth: 450
            imgixParams: { fm: "jpg", auto: "compress" }
          ) {
            src
            srcSet
            sizes
          }
        }
      }
    }
    datoCmsHome {
      introText
      videoId
      altVideoId
      videoPoster {
        fluid(maxWidth: 800) {
          src
        }
      }
    }
  }
`;
